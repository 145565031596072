import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeLemuComponent } from './home-lemu/home-lemu.component';
import { LogInComponent } from './log-in/log-in.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';

export const routes: Routes = [    
  { path: '', component: LogInComponent},
  { path: 'login', component: LogInComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'recover-password/:id', component: RecoverPasswordComponent },
  { path: 'home', component: HomeLemuComponent},
  { path: '**', component: LogInComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
