import { Injectable } from '@angular/core';
import { Auth, signInWithPopup, GoogleAuthProvider, signOut, authState, OAuthProvider } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<any>;

  constructor(private auth: Auth, private router: Router, public snackBar: MatSnackBar, private http: HttpClient) {
    this.user$ = authState(this.auth);
  }

  async loginWithGoogle(): Promise<void> {
    try {
      const provider = new GoogleAuthProvider();
      const result: any = await signInWithPopup(this.auth, provider);
  
      const user = result.user;
      const tokenId = result._tokenResponse.idToken;

      const decoded = jwtDecode(tokenId);

      // console.log("Usuario autenticado:", user);
      // console.log("Nombre:", user.displayName);
      // console.log("Email:", user.email);
      // console.log("Foto:", user.photoURL);
      // console.log("Número de teléfono:", user.phoneNumber);
  
      await this.submitForm(user.email, tokenId);
    } catch (error) {
      this.snackBar.open(`Error de autenticación, no pudiste iniciar sesión con tu cuenta de Google.`, "Aceptar", {
        duration: 20000,
        panelClass: ['simpli-snack']
      });
    }
  }
  

  async loginWithApple() {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email'); // Puedes agregar 'name' si lo necesitas
    try {
      const result = await signInWithPopup(this.auth, provider);
      const user = result.user;

      console.log("Usuario autenticado:", user);
      console.log("Nombre:", user.displayName);
      console.log("Email:", user.email);
      console.log("Foto:", user.photoURL);
      console.log("Número de teléfono:", user.phoneNumber);

      this.router.navigate(['/home']);
      
      console.log('Usuario autenticado:', result.user);
    } catch (error) {
      console.error('Error al iniciar sesión con Apple:', error);
    }
  }

  async logout() {
    await signOut(this.auth);
    this.router.navigate(['/login']);
  }

  async submitForm(email:string, token:string) {

        
    var endpoint = SettingsService.API_ENDPOINT + 'login/';

    let value = {'email': email, 'password':null, 'platform': 'web', 'is_google_auth': true, 'IDToken': token};

    try{
      this.http.post<any>(endpoint, value).subscribe(data => {


        localStorage.setItem("token", data.token);
        let role_code = data.user.roles[0].code;
        localStorage.setItem("role", role_code);
        localStorage.setItem("email", data.user.email);

        
        localStorage.setItem('user_logged',data.user.email)
        if(localStorage.getItem('theming') == null || localStorage.getItem('theming') == "" || localStorage.getItem('theming') == undefined){
          localStorage.setItem('theming','rose-red');
        }


        this.router.navigate(['home']); 

      },
      (error)=>{



        this.snackBar.open(`${error.error.error[0].message}`, "Aceptar", {
          duration: 20000,
          panelClass: ['simpli-snack']
        });



        }
      )
    }catch(error:any){

      this.snackBar.open(`${error.error.error[0].message}`, "Aceptar", {
        duration: 20000,
        panelClass: ['simpli-snack']
      });


    }
  
  
  
      
  }

}
