import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-spashscreen',
    templateUrl: './spashscreen.html',
    styleUrls: ['./spashscreen.scss'],
    standalone: false
})
export class SpashScreen  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
