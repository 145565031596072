import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeLemuComponent } from './home-lemu/home-lemu.component';
import { LogInComponent } from './log-in/log-in.component';
import { RecoverPasswordComponent, DialogMessage } from './recover-password/recover-password.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MaterialModule } from './material/material.module';
import { DialogErrors } from './settings/dialog-errors/dialog-errors';
import { SpashScreen } from './spashscreen/spashscreen';
import { SystemCACA } from './home-lemu/sistemas/system.component';



@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    ForgotPasswordComponent,
    HomeLemuComponent,
    RecoverPasswordComponent,
    DialogMessage,
    DialogErrors,
    SpashScreen,
    SystemCACA,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: true, enableTracing:true }),
    LayoutModule
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginModule { }
