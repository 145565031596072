<div class="login-container">

  <div class="right-side" [ngStyle]="{ 'background-image': 'url(' + randomImage + ')' }">

    <mat-card *ngIf="showForm" class="login-card animate__animated animate__fadeInRight" style="position: relative;">
      <mat-card-content style="width: -webkit-fill-available;">

        <div class="message animate__animated animate__fadeInRight">
          crea tu nueva contraseña
        </div>

        <div style="text-align: center;">
          <img src="../../assets/img/simplilogo.svg" style="height: 100px;margin-top: 20px;margin-bottom: 20px;"/>
  
            <!-- <img src="../../assets/img/simplilogo_old.svg" style="height: 40px;margin-top: 20px;margin-bottom: 20px;margin-right: 18px;"/> -->
        </div>
    
        <form [formGroup]="valForm" (submit)="submit_form($event, valForm.value)" class="animate__animated animate__fadeIn">
    
          <mat-form-field color="primary" appearance="fill" style="width: 100%;">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>        
            <button matSuffix mat-icon-button type="button" (click)="hide = !hide" [attr.aria-label]="'Ocultar password'"
              [attr.aria-pressed]="hide">
              <mat-icon style="color:var(--simpli-secondary);" class="simpli-color-gris">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>Ingresa tu nueva contraseña</mat-hint>
            <mat-error *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-error *ngIf="valForm.controls['password'].hasError('minlength')">
              Debe contener un mínimo de 6 caracteres
            </mat-error>
          </mat-form-field>
    
          <mat-form-field color="primary" appearance="fill" style="width: 100%;">
            <mat-label>Repite tu contraseña</mat-label>
            <input matInput formControlName="re_password" [type]="hide2 ? 'password' : 'text'" required>
            <button matSuffix mat-icon-button type="button" (click)="hide2 = !hide2" [attr.aria-label]="'Ocultar password'"
              [attr.aria-pressed]="hide2">
              <mat-icon style="color:var(--simpli-secondary);" class="simpli-color-gris">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint>Vuelve a ingresar tu nueva contraseña</mat-hint>
            <mat-error *ngIf="valForm.controls['re_password'].hasError('required') && (valForm.controls['re_password'].dirty || valForm.controls['re_password'].touched)">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-error *ngIf="valForm.get('re_password')?.hasError('equalTo')">
              La contraseña ingresada no coincide
            </mat-error>
          </mat-form-field>
    
    
          <div class="btn-container">
            <button class="primary-button-log" [disabled]="show_spinner" mat-flat-button type="submit" color="primary">
              <mat-label *ngIf="show_spinner == false">Crear contraseña</mat-label>
              <span *ngIf="show_spinner == true"><i class="fa-solid fa-circle-notch fa-spin" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
            </button>
          </div>
    
    
    
        </form>
    
  
        <div class="center-div">
          <a mat-button routerLink="/login" style="color:var(--simpli-secondary);">Volver</a>
        </div>
  
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!showForm" class="login-card animate__animated animate__fadeInRight" style="position: relative;">
      <mat-card-content style="width: -webkit-fill-available;">

        <div style="text-align: center;">
          <img src="../../assets/img/simpli-azul-rosa.svg" style="height: 100px;margin-top: 20px;margin-bottom: 20px;"/>
        </div>
    
        <div class="simpli-floating-box-body">
    
    
          <div class="text-center" style="text-align: center;">
              <span style="padding-top: 50px;display: block;">
                <strong >¡Ups! Algo ocurrió en el proceso</strong> 
                Este enlace ha caducado. Solicita nuevamente la recuperación de contraseña y recibirás uno nuevo.
              </span>
      
              <div class="btn-container">
                <button class="primary-button-log" routerLink="/forgot-password" mat-flat-button type="button" color="primary">
                  <mat-label >Volver a solicitar</mat-label>
                </button>
              </div>
      
      
          </div>
    
        </div>
    
        <div class="center-div">
          <a mat-button routerLink="/login" style="color: var(--simpli-secondary);">Volver</a>
        </div>
  
      </mat-card-content>
    </mat-card>
  </div>
</div>
