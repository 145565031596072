<div mat-dialog-title>

</div>

<div mat-dialog-content>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top p-50">
        <div class="text-center">
            <img src="assets/img/check.png" alt="">
            <p class="simpli-color3">
                {{ msg }}
            </p>
        </div>
        <div class="text-center">
            <button mat-dialog-close type="button" routerLink="/login" class="simpli-floating-box-button">Ingresar a mi cuenta</button>
        </div>
    </div>

</div>