import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { AuthService } from '../services/auth.service';


@Component({
    selector: 'app-log-in',
    templateUrl: './log-in.component.html',
    styleUrls: ['./log-in.component.less'],
    standalone: false
})
export class LogInComponent implements OnInit {

  valForm: FormGroup;
  hide:boolean=true;


  constructor(fb: FormBuilder, private http: HttpClient , private router: Router, public snackBar: MatSnackBar, public settings: SettingsService,
    private authService: AuthService,
  ) {

    this.valForm = fb.group({
      'email': ['', Validators.compose([Validators.required, CustomValidators.email])],
      'password': ['', Validators.compose([Validators.required])],
      'platform': ['web']
    });
    
   }

   randomImage: string = '';
   private images: string[] = [
     '../../assets/images/33.jpg',
     '../../assets/images/3.jpg',
     '../../assets/images/4.jpg',
     '../../assets/images/7.jpg',
     '../../assets/images/9.jpg',
     '../../assets/images/12.jpg',
     '../../assets/images/14.jpg',
     '../../assets/images/17.jpg',
     '../../assets/images/23.jpg',
     '../../assets/images/2.jpg',
     '../../assets/images/15.jpg',
     '../../assets/images/26.jpg',
   ];

  setRandomBackground(): void {
    const randomIndex = Math.floor(Math.random() * this.images.length);
    this.randomImage = this.images[randomIndex];
  }
  

  ngOnInit(): void {

    this.setRandomBackground();

    let token = localStorage.getItem('token');
    let role = localStorage.getItem('role')

    if(token != null){

      setTimeout(()=>{
        let url = SettingsService.lemu_base
        this.router.navigate(['home']); 
      }, 1500);
    }

  }

  disabled:boolean=false;
  submitForm($ev:any, value: any) {
    $ev.preventDefault();

    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {

      this.disabled = true;
      
      var endpoint = SettingsService.API_ENDPOINT + 'login/'

      try{
        this.http.post<any>(endpoint, value).subscribe(data => {


          localStorage.setItem("token", data.token);
          let role_code = data.user.roles[0].code;
          localStorage.setItem("role", role_code);
          localStorage.setItem("email", data.user.email);
  
          
          localStorage.setItem('user_logged',data.user.email)
          if(localStorage.getItem('theming') == null || localStorage.getItem('theming') == "" || localStorage.getItem('theming') == undefined){
            localStorage.setItem('theming','rose-red');
          }
  
          this.disabled = false;
          this.router.navigate(['home']); 
  
        },
        (error)=>{
  

  
          this.snackBar.open(`${error.error.error[0].message}`, "Aceptar", {
            duration: 20000,
            panelClass: ['simpli-snack']
          });
  
          this.disabled = false;
  
         }
        )
      }catch(error:any){

        this.snackBar.open(`${error.error.error[0].message}`, "Aceptar", {
          duration: 20000,
          panelClass: ['simpli-snack']
        });
        this.disabled = false;

      }



    }
  }


  forgot(){
    this.router.navigate(['/forgot-password'])
  }

  loginGoogle() {
    this.disabled = true;
    this.valForm.disable();
    this.authService.loginWithGoogle().finally(() => {
      this.disabled = false; 
      this.valForm.enable();
    });
  }


  

}
