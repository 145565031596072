import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../../settings/settings.service';

@Component({
    selector: 'app-log-system',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.less']
})

export class SystemCACA implements OnInit {

    @Input() name:any;
    @Input() url:any;
    @Input() initials:any;
    @Input() index:any

    constructor(){

    }

    ngOnInit(): void {
        
    }




}

