import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
declare var $ :any;


@Component({
    selector: 'dialog-errors',
    templateUrl: './dialog-errors.html',
    styleUrls: ['./dialog-errors.less']
})

export class DialogErrors implements OnInit {

    @Input() title: string = "Loading Title";
    @Input() subtitle: string = "Vista Previa";
    @Input() mensajeprincipal: string = "";
    @Input() mensajenegrita: string = "";
    @Input() icon: string = "autorenew";
    @Input() coloricon: string = "#ff4081";
    @Input() errores:any;

    constructor(
        
        public dialogRef: MatDialogRef<DialogErrors>) {


    }

    ngOnInit() {
        setTimeout(() => {
            this.dialogRef.close(true)
        }, 20000);
    }

    close_dialog() {
        this.dialogRef.close(true)
    }

}
