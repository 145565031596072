import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { SettingsService } from '../settings/settings.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {

  valForm: FormGroup;
  hide:boolean=true;


  constructor(fb: FormBuilder, private http: HttpClient , private router: Router, public snackBar: MatSnackBar) {

    this.valForm = fb.group({
      'email': ['', Validators.compose([Validators.required, CustomValidators.email])],
    });
    
   }

  ngOnInit(): void {
  }

  disabled:boolean=false;
  submitForm($ev:any, value: any) {
    $ev.preventDefault();

    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {

      this.disabled = true;
      
      var endpoint = SettingsService.API_ENDPOINT + 'recover-password-mail/'
      this.http.post<any>(endpoint, value).subscribe(data => {


        this.disabled = false;

        this.snackBar.open("Se envio un correo con las intrucciones de recuperación", "Aceptar", {
          duration: 20000,
          panelClass: 'simpli-snack'
        });

        this.router.navigate(['/login'])



      },
      (error)=>{

        this.snackBar.open("El correo ingresado no se encuentra registrado en Simpli", "Aceptar", {
          duration: 20000,
          panelClass: 'simpli-snack'
        });

        this.valForm.controls['email'].setErrors({ 'required': true });

        this.disabled = false;

       }
      )


    }
  }

}
