import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';


@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.less']
})
export class LogInComponent implements OnInit {

  valForm: FormGroup;
  hide:boolean=true;


  constructor(fb: FormBuilder, private http: HttpClient , private router: Router, public snackBar: MatSnackBar, public settings: SettingsService,) {

    this.valForm = fb.group({
      'email': ['', Validators.compose([Validators.required, CustomValidators.email])],
      'password': ['', Validators.compose([Validators.required])]
    });
    
   }

  ngOnInit(): void {
    let token = localStorage.getItem('token');
    let role = localStorage.getItem('role')

    if(token != null){

      setTimeout(()=>{
        let url = SettingsService.lemu_base
        this.router.navigate(['home']); 
      }, 1500);
    }

  }

  disabled:boolean=false;
  submitForm($ev:any, value: any) {
    $ev.preventDefault();

    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {

      this.disabled = true;
      
      var endpoint = SettingsService.API_ENDPOINT + 'login/'

      try{
        this.http.post<any>(endpoint, value).subscribe(data => {


          localStorage.setItem("token", data.token);
          let role_code = data.user.roles[0].code;
          localStorage.setItem("role", role_code);
          localStorage.setItem("email", data.user.email);
  
          
          localStorage.setItem('user_logged',data.user.email)
          if(localStorage.getItem('theming') == null || localStorage.getItem('theming') == "" || localStorage.getItem('theming') == undefined){
            localStorage.setItem('theming','rose-red');
          }
  
          this.disabled = false;
          this.router.navigate(['home']); 
  
        },
        (error)=>{
  

  
          this.snackBar.open(`${error.error.error[0].message}`, "Aceptar", {
            duration: 20000,
            panelClass: ['simpli-snack']
          });
  
          this.disabled = false;
  
         }
        )
      }catch(error:any){

        this.snackBar.open(`${error.error.error[0].message}`, "Aceptar", {
          duration: 20000,
          panelClass: ['simpli-snack']
        });
        this.disabled = false;

      }



    }
  }


  forgot(){
    this.router.navigate(['/forgot-password'])
  }

}
