import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../settings/settings.service';


declare var $ :any;

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.less']
})
export class RecoverPasswordComponent implements OnInit {

  valForm!: FormGroup;
  verification_code: string = "";
  sub: any;
  showForm: boolean = true;
  show_spinner: boolean = false;
  hide: boolean = true;
  hide2: boolean = false;

  constructor(fb: FormBuilder, private route: ActivatedRoute, public snackBar: MatSnackBar, public settings: SettingsService, private router: Router, public dialog: MatDialog, private change:ChangeDetectorRef) {

    let password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    let cPassword = new FormControl(null, Validators.compose([Validators.required, CustomValidators.equalTo(password)]));

    this.valForm = fb.group({
      'password': password,
      're_password': cPassword
    });

    this.sub = this.route.params.subscribe(params => {
      this.verification_code = params['id'];
    });
    
  }

  ngOnInit() {
    this.verify_code();
    this.change.detectChanges();
  }
  

  // Back nuevo app cliente
  async verify_code() {
    try {
      const bd: any = await this.settings.get_query(1, `cliente/recover-password/?verification_code=${this.verification_code}`, {}, true);

      switch (bd.status) {
        case 200:
          this.showForm=true;
        break;
        default:

        break;
      }
    } catch (bd: any) {
      console.log("error", bd)
      this.showForm=false;
      this.settings.manage_errors(bd.status, "Verificar código", bd, false);
    }
  }

  async submit_form($ev: any, value: any) {
    value["verification_code"]=this.verification_code;
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      try {
        this.show_spinner = true;
        const bd: any = await this.settings.get_query(3, `cliente/recover-password/`, value, true);


        this.show_spinner = false;
        this.router.navigate(['/login']);
        this.snackBar.open("Contraseña cambiada con exito", "Aceptar", {
          duration: 20000,
          panelClass: 'simpli-snack'
        });



      } catch (bd: any) {
        console.log("ERROR", bd)
        this.show_spinner = false;
        this.settings.manage_errors(bd.status, "Cambiar contraseña", bd, false);
      }
    }
  }

  dialog_message(){
    const dialogRef = this.dialog.open(DialogMessage,{
      width:'600px'
    });
    dialogRef.componentInstance.msg = "¡Has creado tu contraseña con éxito! Ahora podrás volver a ingresar a tu cuenta."
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  // Back nuevo app cliente

}

@Component({
  templateUrl: './dialog-message.html',
})
export class DialogMessage implements OnInit {
  msg: string = "";
  constructor(public dialogRef: MatDialogRef<DialogMessage>) {
  }
  ngOnInit() {
  }
}
