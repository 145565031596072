import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.less',
    standalone: false
})
export class AppComponent implements OnInit{
  title = 'lemu-login';



  constructor(private router: Router, private route: ActivatedRoute){

  }

  currentUrl:string="";
  ngOnInit(): void {

    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
    });

    this.checkToken();
  }

  loading = false;
  async checkToken() {


    this.loading = true;
    const token = localStorage.getItem('token');


    setTimeout(()=>{
      if (!token) {
        if(this.currentUrl.includes('recover-password') || this.currentUrl.includes('forgot-password')){
          console.log(true)
        }else{
          this.router.navigate(['/login'], { replaceUrl: true });
        }

      }else{
        if(this.currentUrl.includes('recover-password') || this.currentUrl.includes('forgot-password')){
          console.log(true)
        }else{
          this.router.navigate(['/home'], { replaceUrl: true });
        }
      }

      this.loading = false;

    }, 1500);



  }
}
