export const environment = {
    production: false,
    API_ENDPOINT: 'https://api.dev.api-arrayan.cl/',
    lemu_base: 'https://dev.api-arrayan.cl/',
    clientes_base: 'https://dev.api-arrayan.cl/portal-cliente',
    WS_URL: 'wss://luq81qnp8d.execute-api.us-east-2.amazonaws.com/dev',

    configFirebase :{
        apiKey: "AIzaSyBWHJKRNz8ompsxt929l0iKaLNvIB4z62k",
        authDomain: "lemu-app-24bd2.firebaseapp.com",
        projectId: "lemu-app-24bd2",
        storageBucket: "lemu-app-24bd2.firebasestorage.app",
        messagingSenderId: "1042597607125",
        appId: "1:1042597607125:web:550bd23e94807ebc86545c",
        measurementId: "G-TN3ZLC57EP"
    }

}
