

<div   [ngClass]="{'box-container animate__animated animate__fadeInRight': true}">
    <div class="d_system"> 
        <div class="lemu_initials">
            :{{ this.initials }}
        </div>

        <div style="margin-top: 10px;margin-bottom: 10px;"></div>

        <div class="lemu_name"> 
            {{this.name}} 
        </div>
    </div>
</div>

